import React from "react";
import styled from "styled-components";
import Gif from "../assets/Home Video.mp4";

const CoverVideo = () => {
  return (
    <VideoContainer>
      <video autoPlay muted loop playsInline>
        <source src={Gif} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Overlay>
        <Title>Skinny Bob</Title>
        <Subtitle>The Intergalactic Meme Coin</Subtitle>
      </Overlay>
    </VideoContainer>
  );
};

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 64em) {
    height: 400px;
  }

  @media (max-width: 48em) {
    height: 300px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  color: ${props => props.theme.body};
  font-size: 3rem;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 0.5rem;

  @media (max-width: 64em) {
    font-size: 2.5rem;
  }

  @media (max-width: 48em) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  color: ${props => props.theme.body};
  font-size: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 64em) {
    font-size: 1.25rem;
  }

  @media (max-width: 48em) {
    font-size: 1rem;
  }
`;

export default CoverVideo;