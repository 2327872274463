import React from "react";
import styled from "styled-components";

import img1 from "../assets/Nfts/bighead.webp";
import img2 from "../assets/Nfts/bighead-1.webp";
import img3 from "../assets/Nfts/bighead-2.webp";
import img4 from "../assets/Nfts/bighead-3.webp";
import img5 from "../assets/Nfts/bighead-4.webp";
import img6 from "../assets/Nfts/bighead-5.webp";

const FooterBanner = () => {
  return (
    <Section>
      <ImgContainer>
        <img src={img1} alt="Skinny BOB" />
        <img src={img2} alt="Skinny BOB" />
        <img src={img3} alt="Skinny BOB" />
        <img src={img4} alt="Skinny BOB" />
        <img src={img5} alt="Skinny BOB" />
        <img src={img6} alt="Skinny BOB" />
      </ImgContainer>
      <Content>
        <Title>
          Join the <br /> Skinny BOB Club
        </Title>
        <BtnContainer>
          <a href="https://nft.skinnybob.net" target="_blank" rel="noreferrer">
            <JoinNow>Claim your NFT!</JoinNow>
          </a>
        </BtnContainer>
      </Content>
    </Section>
  );
};

const Section = styled.div`
  width: 100vw;
  height: 25rem;
  position: relative;
  border-top: 2px solid ${(props) => props.theme.text};
  background-color: ${(props) => `rgba(${props.theme.textRgba}, 0.9)`};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 2rem;

  @media (max-width: 48em) {
    height: auto;
    padding: 1rem;
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 12rem;
    height: auto;
    transition: transform 0.3s ease;
  }

  @media (max-width: 48em) {
    img {
      width: 8rem;
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 10;
  text-align: center;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxxl};
  font-weight: 600;
  color: ${(props) => props.theme.body};
  text-shadow: 2px 2px 4px ${(props) => props.theme.text};
  margin-bottom: 1.5rem;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxxl};
  }

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const JoinNow = styled.button`
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 700;
  padding: 1rem 2.5rem;
  border-radius: 50px;
  border: 2px solid ${(props) => props.theme.text};
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: scale(1.05);
    background-color: ${(props) => props.theme.text};
    color: ${(props) => props.theme.body};
  }

  @media (max-width: 48em) {
    padding: 0.75rem 2rem;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    padding: 0.5rem 1.5rem;
    font-size: ${(props) => props.theme.fontsm};
  }
`;

export default FooterBanner;
