// This file contains variables for different thremes

export const lightTheme = {
  body: '#ffffff',
  text: '#333333',
  textLight: '#666666',
  cardBg: '#f0f0f0',
  accent: '#4CAF50',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  uniswapTheme: 'light'
};

export const darkTheme = {
  body: '#1a1a1a',
  text: '#ffffff',
  textLight: '#cccccc',
  cardBg: '#2a2a2a',
  accent: '#4CAF50',
  shadowColor: 'rgba(255, 255, 255, 0.1)',
  uniswapTheme: 'dark'
};

export const Light = {
  body: "#fff",
  text: "#202020",
  bodyRgba: "255, 255, 255",
  textRgba: "32,32,32",

  carouselColor: "#EEEDDE",

  fontxs: "0.75rem",
  fontsm: "0.875rem",
  fontmd: "1rem", // 1rem = 16px
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "4rem",

  fontButton: "0.875rem",

  navHeight: "5rem",
};

export const Dark = {
  body: "#202020",
  text: "#fff",
  bodyRgba: "32,32,32",
  textRgba: "255, 255, 255",

  carouselColor: "#EEEDDE",

  fontxs: "0.75rem",
  fontsm: "0.875rem",
  fontmd: "1rem", // 1rrem = 16px
  fontlg: "1.25rem",
  fontxl: "2rem",
  fontxxl: "3rem",
  fontxxxl: "4rem",

  fontButton: "0.875rem",

  navHeight: "5rem",
};
