import React from "react";
import { SwapWidget, darkTheme, lightTheme } from '@uniswap/widgets';
import '@uniswap/widgets/fonts.css';
import styled from 'styled-components';

// Default token list from Uniswap
const UNISWAP_TOKEN_LIST = 'https://raw.githubusercontent.com/khairfa/uniswap-token-list/main/tokens-list.json';

// Use the native token of the connected chain as the default input token
const NATIVE = 'NATIVE'; // Special address for native token

// SKIBOB as the default output token
const SKIBOB = '0x1a936953C6C5f832032C2A3CE40dA1C0934860a6';

const UniswapWidget = ({ theme }) => {
  const widgetConfig = {
    tokenList: UNISWAP_TOKEN_LIST,
    defaultInputTokenAddress: NATIVE,
    defaultOutputTokenAddress: SKIBOB,
    width: "100%"
  };

  return (
    <WidgetWrapper>
      <SwapWidget 
        {...widgetConfig}
        theme={theme.uniswapTheme === 'light' ? lightTheme : darkTheme}
      />
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export default UniswapWidget;