import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import img1 from "../assets/Nfts/bighead.webp";
import img2 from "../assets/Nfts/bighead-1.webp";
import img3 from "../assets/Nfts/bighead-2.webp";
import img4 from "../assets/Nfts/bighead-3.webp";
import img5 from "../assets/Nfts/bighead-4.webp";
import img6 from "../assets/Nfts/bighead-5.webp";
import img7 from "../assets/Nfts/bighead-6.webp";
import img8 from "../assets/Nfts/bighead-7.webp";
import img9 from "../assets/Nfts/bighead-8.webp";
import img10 from "../assets/Nfts/bighead-9.webp";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];

const Carousel = () => {
  return (
    <Container>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <ImageContainer>
              <img src={img} alt={`Skinny Bob NFT ${index + 1}`} />
            </ImageContainer>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem 0;

  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }

  .swiper-pagination-bullet {
    background: ${(props) => props.theme.text};
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: ${(props) => props.theme.accent};
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${(props) => props.theme.accent};
    
    &:after {
      font-size: 2rem;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.4);
  }
`;

export default Carousel;