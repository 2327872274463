import React from "react";
import styled from "styled-components";

const Button = ({ text, link }) => {
  return (
    <ButtonWrapper href={link} aria-label={text} target="_blank" rel="noreferrer">
      <ButtonContent>
        <ButtonText>{text}</ButtonText>
        <ButtonIcon>→</ButtonIcon>
      </ButtonContent>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.a`
  display: inline-block;
  background-color: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.body};
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;
`;

const ButtonText = styled.span`
  font-size: ${(props) => props.theme.fontmd};
  font-weight: 300;
  letter-spacing: 0.5px;
`;

const ButtonIcon = styled.span`
  margin-left: 0.5rem;
  font-size: 1.2em;
  transition: transform 0.3s ease;

  ${ButtonWrapper}:hover & {
    transform: translateX(3px);
  }
`;

export default Button;