import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../logo.webp";

const Logo = () => {
  return (
    <LogoContainer to="/">
      <LogoImageWrapper>
        <LogoImage src={logo} alt="Skinny BoB Logo" />
      </LogoImageWrapper>
      <LogoTextContainer>
        <LogoTitle>Skinny BoB</LogoTitle>
        <LogoSubtitle>Alien MemeCoin</LogoSubtitle>
      </LogoTextContainer>
    </LogoContainer>
  );
};

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-2px);
  }
`;

const LogoImageWrapper = styled.div`
  width: 64px;  /* Increased size */
  height: 64px; /* Increased size */
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.accent};
  transition: border-color 0.3s ease;

  ${LogoContainer}:hover & {
    border-color: ${(props) => props.theme.accentHover};
  }
`;

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed from cover to contain */
  transition: transform 0.3s ease;

  ${LogoContainer}:hover & {
    transform: scale(1.1);
  }
`;

const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const LogoTitle = styled.span`
  font-size: ${(props) => props.theme.fontxl};
  font-weight: bold;
  color: ${(props) => props.theme.text};
  transition: color 0.3s ease;

  ${LogoContainer}:hover & {
    color: ${(props) => props.theme.accent};
  }
`;

const LogoSubtitle = styled.span`
  font-size: ${(props) => props.theme.fontxs};
  color: ${(props) => props.theme.textLight};
  transition: color 0.3s ease;

  ${LogoContainer}:hover & {
    color: ${(props) => props.theme.accentHover};
  }
`;

export default Logo;
