import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { FaEthereum } from 'react-icons/fa';

import img1 from "../../assets/Nfts/bighead.webp";
import img2 from "../../assets/Nfts/bighead-1.webp";
import img3 from "../../assets/Nfts/bighead-2.webp";
import img4 from "../../assets/Nfts/bighead-3.webp";
//import img5 from "../../assets/Nfts/bighead-4.webp";
//import img6 from "../../assets/Nfts/bighead-5.webp";
//import img7 from "../../assets/Nfts/bighead-6.webp";
//import img8 from "../../assets/Nfts/bighead-7.webp";
//import img9 from "../../assets/Nfts/bighead-8.webp";
//import img10 from "../../assets/Nfts/bighead-9.webp";

const nftData = [
  { img: img1, number: 1, price: 0.05 },
  { img: img2, number: 2, price: 0.07 },
  { img: img3, number: 3, price: 0.08 },
  { img: img4, number: 4, price: 0.06 },
  //{ img: img5, number: 5, price: 0.09 },
  //{ img: img6, number: 6, price: 0.1 },
  //{ img: img7, number: 7, price: 0.12 },
  //{ img: img8, number: 8, price: 0.15 },
  //{ img: img9, number: 9, price: 0.18 },
  //{ img: img10, number: 10, price: 0.2 },
];

const Showcase = () => {
  return (
    <Section id="showcase">
      <Title>Skinny Bob NFT Collection</Title>
      <Container>
        {nftData.map((nft, index) => (
          <NftItem key={index} {...nft} />
        ))}
      </Container>
      <CTAButton href="https://nft.skinnybob.net" target="_blank" rel="noopener noreferrer">
        View Full Collection
      </CTAButton>
    </Section>
  );
};

const NftItem = ({ img, number, price }) => {
  return (
    <ItemContainer>
      <ImgContainer>
        <img src={img} alt={`Skinny Bob #${number}`} />
      </ImgContainer>
      <Details>
        <ItemInfo>
          <span>Skinny BOB</span>
          <h3>#{number}</h3>
        </ItemInfo>
        <PriceTag>
          <FaEthereum />
          <span>{price} ETH</span>
        </PriceTag>
      </Details>
    </ItemContainer>
  );
};

const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.body};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
`;

const Title = styled.h2`
  font-size: 3rem;
  margin-bottom: 3rem;
  color: ${props => props.theme.text};
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 1200px;
`;

const ItemContainer = styled.div`
  width: 250px;
  background-color: ${props => props.theme.cardBg};
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 250px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.1);
  }
`;

const Details = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemInfo = styled.div`
  span {
    font-size: 0.8rem;
    color: ${props => props.theme.textLight};
  }

  h3 {
    font-size: 1.2rem;
    color: ${props => props.theme.text};
    margin-top: 0.2rem;
  }
`;

const PriceTag = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.accent};
  font-weight: bold;

  svg {
    margin-right: 0.5rem;
  }
`;

const CTAButton = styled.a`
  margin-top: 3rem;
  padding: 1rem 2rem;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.body};
  border-radius: 50px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

export default Showcase;