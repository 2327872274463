import React from "react";
import styled from "styled-components";
import img1 from "../../logo.png";
import Background from '../../assets/mosaique-opacite.webp';
import { FaPercent, FaLock, FaWater } from 'react-icons/fa';

const Tokenomics = () => {
  return (
    <Section id="tokenomics">
      <Container>
        <Title>Tokenomics</Title>
        <FlexContainer>
          <LogoBox>
            <ImgContainer>
              <img src={img1} alt="Skinny BoB" />
              <Details>
                <TokenName>Skinny BOB</TokenName>
                <TokenOrigin>From Zeta Reticuli</TokenOrigin>
              </Details>
            </ImgContainer>
          </LogoBox>
          <ContentBox>
            <TokenSupply>
              Total Supply: 370,281,309,753,600 SKIBOB
            </TokenSupply>
            <FeaturesContainer>
              <Feature>
                <FeatureIcon><FaPercent /></FeatureIcon>
                <FeatureContent>
                  <FeatureTitle>No Taxes</FeatureTitle>
                  <FeatureText>It's that simple. No hidden fees or charges.</FeatureText>
                </FeatureContent>
              </Feature>
              <Feature>
                <FeatureIcon><FaWater /></FeatureIcon>
                <FeatureContent>
                  <FeatureTitle>High Liquidity</FeatureTitle>
                  <FeatureText>93.1% of tokens in liquidity pool for stability.</FeatureText>
                </FeatureContent>
              </Feature>
              <Feature>
                <FeatureIcon><FaLock /></FeatureIcon>
                <FeatureContent>
                  <FeatureTitle>Reserved Tokens</FeatureTitle>
                  <FeatureText>6.9% reserved for future listings and bridges.</FeatureText>
                </FeatureContent>
              </Feature>
            </FeaturesContainer>
            <CallToAction href="https://app.uniswap.org/#/swap?outputCurrency=0x1a936953C6C5f832032C2A3CE40dA1C0934860a6&chain=mainnet" target="_blank" rel="noopener noreferrer">Get $SKIBOB Now</CallToAction>

            
          </ContentBox>
        </FlexContainer>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-image: url(${Background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.text};
  padding: 4rem 0;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 2rem;
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const LogoBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const ImgContainer = styled.div`
  width: 25rem;
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px ${(props) => props.theme.shadowColor};
  
  @media (max-width: 768px) {
    width: 18rem;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const Details = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.cardBg};
`;

const TokenName = styled.h3`
  font-size: 1.5rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const TokenOrigin = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.textLight};
`;

const ContentBox = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px ${(props) => props.theme.shadowColor};
`;

const TokenSupply = styled.p`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: ${(props) => props.theme.text};
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.accent};
`;

const FeatureContent = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h4`
  font-size: 1.2rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const FeatureText = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.textLight};
`;

const CallToAction = styled.a`
  display: inline-block;
  margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.body};
  text-decoration: none;
  border-radius: 50px;
  font-weight: bold;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px ${(props) => props.theme.shadowColor};
  }
`;

export default Tokenomics;