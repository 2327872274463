import styled from "styled-components";
import ConfettiComponent from "../Confetti";

import img1 from "../../assets/Nfts/bighead.webp";
import img2 from "../../assets/Nfts/bighead-1.webp";
import img3 from "../../assets/Nfts/bighead-2.webp";
import img4 from "../../assets/Nfts/bighead-3.webp";
import img5 from "../../assets/Nfts/bighead-4.webp";
import img6 from "../../assets/Nfts/bighead-5.webp";
import img7 from "../../assets/Nfts/bighead-6.webp";
import img8 from "../../assets/Nfts/bighead-7.webp";
import img9 from "../../assets/Nfts/bighead-8.webp";

const Team = () => {
  return (
    <Section id="team">
      <ConfettiComponent />
      <Title>Our Team</Title>
      <Container>
        <MemberComponent img={img1} name="Stellar Sage" position="Chief Explorer" />
        <MemberComponent img={img2} name="Quantum Questor" position="Lead Developer" />
        <MemberComponent img={img3} name="Nebula Navigator" position="Community Ambassador" />
        <MemberComponent img={img4} name="Galactic Guru" position="Chief Strategist" />
        <MemberComponent img={img5} name="Cosmic Cryptographer" position="Security Specialist" />
        <MemberComponent img={img6} name="Astro Analyst" position="Market Researcher" />
        <MemberComponent img={img7} name="Lunar Luminary" position="Creative Director" />
        <MemberComponent img={img8} name="Celestial Counselor" position="Support Specialist" />
        <MemberComponent img={img9} name="Stargazer Strategist" position="Partnerships Manager" />
      </Container>
    </Section>
  );
};

const MemberComponent = ({ img, name, position }) => {
  return (
    <Item>
      <ImgContainer>
        <img src={img} alt={name} />
      </ImgContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  position: relative;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  font-weight: 600;
  color: ${(props) => props.theme.text};
  margin-bottom: 3rem;
  text-transform: uppercase;
  letter-spacing: 2px;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 64em) {
    width: 90%;
  }

  @media (max-width: 48em) {
    width: 95%;
  }
`;

const Item = styled.div`
  width: 18rem;
  padding: 1.5rem;
  background-color: ${(props) => props.theme.body};
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);

    img {
      transform: scale(1.1);
    }
  }

  @media (max-width: 30em) {
    width: 90%;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1.5rem;

  img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
`;

const Name = styled.h2`
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 700;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const Position = styled.h3`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.8)`};
  font-weight: 500;
`;

export default Team;
