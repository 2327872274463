import React from "react";
import styled from "styled-components";
import CoverVideo from "../CoverVideo";
import { FaRocket, FaLaugh, FaCoins } from 'react-icons/fa';
import Background from '../../assets/mosaique-opacite.webp';

const Home = () => {
  return (
    <Section id="home">
      <BackgroundOverlay />
      <Container>
        <ContentBox>
          <Title>
            Welcome to <GradientSpan>Skinny Bob</GradientSpan> MemeCoin
          </Title>
          <Subtitle>
            Where Alien Humor Meets Crypto Innovation
          </Subtitle>
          <Features>
            <Feature>
              <FaLaugh /> <span>Meme-Powered</span>
            </Feature>
            <Feature>
              <FaCoins /> <span>Multi-Chain</span>
            </Feature>
            <Feature>
              <FaRocket /> <span>Moon-Bound</span>
            </Feature>
          </Features>
          <Description>
            Join the intergalactic revolution with Skinny Bob MemeCoin. 
            We're not just another token – we're a community-driven phenomenon 
            inspired by the internet's favorite alien visitor.
          </Description>
          <CTAButton href="#about">
            Explore the Skinny Bob Universe
          </CTAButton>
        </ContentBox>
        <VideoBox>
          <CoverVideo />
        </VideoBox>
      </Container>
      <ScrollIndicator href="#about">
        <span></span>
        <span></span>
        <span></span>
      </ScrollIndicator>
    </Section>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 2rem 0;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${Background});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(0.7);
  z-index: -1;
`;

const Container = styled.div`
  width: 85%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media (max-width: 64em) {
    flex-direction: column-reverse;
    width: 100%;
    padding: 0 2rem;
  }
`;

const ContentBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 64em) {
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 3.5rem);
  font-weight: 700;
  color: ${props => props.theme.text};
  margin-bottom: 1rem;
  line-height: 1.2;
`;

const GradientSpan = styled.span`
  background: linear-gradient(to right, #30CFD0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.h2`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  font-weight: 400;
  color: ${props => props.theme.text};
  margin-bottom: 2rem;
`;

const Features = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
  gap: 1rem;

  @media (max-width: 48em) {
    flex-direction: column;
    align-items: center;
  }
`;

const Feature = styled.div`
  display: flex;
  align-items: center;
  font-size: clamp(0.8rem, 2vw, 1.2rem);
  color: ${props => props.theme.text};

  svg {
    margin-right: 0.5rem;
    font-size: 1.5em;
    color: ${props => props.theme.accent};
  }
`;

const Description = styled.p`
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  color: ${props => props.theme.text};
  margin-bottom: 2rem;
  line-height: 1.6;
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.body};
  padding: 0.8em 1.5em;
  border-radius: 50px;
  font-size: clamp(0.8rem, 2vw, 1rem);
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

const VideoBox = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  padding-top:80px;
`;

const ScrollIndicator = styled.a`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  span {
    display: block;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid ${props => props.theme.text};
    border-right: 2px solid ${props => props.theme.text};
    transform: rotate(45deg);
    margin: -5px;
    animation: scrollAnimation 2s infinite;
  }

  span:nth-child(2) {
    animation-delay: -0.2s;
  }

  span:nth-child(3) {
    animation-delay: -0.4s;
  }

  @keyframes scrollAnimation {
    0% {
      opacity: 0;
      transform: rotate(45deg) translate(-10px, -10px);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(45deg) translate(10px, 10px);
    }
  }

  @media (max-width: 48em) {
    display: none;
  }
`;

export default Home;