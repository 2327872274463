import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaCoins, FaPalette } from "react-icons/fa";

const faqData = [
  {
    category: "Tokens",
    icon: <FaCoins />,
    questions: [
      {
        question: "What is $SKIBOB and how does it work?",
        answer: "$SKIBOB is a multi-chain meme token inspired by the Skinny Bob phenomenon. It operates on Ethereum, Binance Smart Chain, and Polygon, offering flexibility and reduced transaction costs. Our tokenomics focus on long-term growth and community engagement."
      },
      {
        question: "How can I purchase $SKIBOB tokens?",
        answer: "You can acquire $SKIBOB tokens through decentralized exchanges like Uniswap (Ethereum), PancakeSwap (BSC), and QuickSwap (Polygon). Ensure you have the correct contract address and sufficient native tokens for gas fees. Always verify the token contract before trading."
      },
      {
        question: "What are the tokenomics of $SKIBOB?",
        answer: "The total supply of $SKIBOB is 370,281,309,753,600. We have implemented anti-whale measures and liquidity locks to ensure fair distribution and prevent market manipulation. A portion of each transaction goes towards community development and marketing."
      }
    ]
  },
  {
    category: "NFTs",
    icon: <FaPalette />,
    questions: [
      {
        question: "What are Skinny Bob NFTs?",
        answer: "Skinny Bob NFTs are unique digital collectibles on the Polygon blockchain. They feature various alien characters inspired by the Skinny Bob lore, each with distinct traits and rarity levels."
      },
      {
        question: "What makes Skinny Bob NFTs unique?",
        answer: "Skinny Bob NFTs offer exclusive community benefits, including access to our DAO, upcoming metaverse events, and potential airdrops. The limited collection of 1000 NFTs ensures rarity and potential for appreciation."
      },
      {
        question: "Are there any utilities for Skinny Bob NFT holders?",
        answer: "Yes, NFT holders will have access to exclusive content, voting rights in our DAO, and priority access to future projects within the Skinny Bob ecosystem, including our upcoming Play-to-Earn game."
      }
    ]
  }
];

const Faqs = () => {
  return (
    <Section id="faqs">
      <Title>Frequently Asked Questions</Title>
      <Container>
        {faqData.map((category, index) => (
          <CategorySection key={index}>
            <CategoryTitle>
              {category.icon}
              <span>{category.category}</span>
            </CategoryTitle>
            {category.questions.map((faq, faqIndex) => (
              <FaqItem
                key={faqIndex}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: faqIndex * 0.1 }}
              >
                <Question>{faq.question}</Question>
                <Answer>{faq.answer}</Answer>
              </FaqItem>
            ))}
          </CategorySection>
        ))}
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  margin-bottom: 3rem;
  text-align: center;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 64em) {
    width: 90%;
    flex-direction: column;
  }
`;

const CategorySection = styled.div`
  width: 48%;
  @media (max-width: 64em) {
    width: 100%;
  }
`;

const CategoryTitle = styled.h2`
  font-size: ${(props) => props.theme.fontxl};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${(props) => props.theme.accent};
  }
`;

const FaqItem = styled(motion.div)`
  background-color: ${(props) => props.theme.cardBg};
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Question = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.accent};
`;

const Answer = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  line-height: 1.5;
`;

export default Faqs;