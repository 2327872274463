import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --font-primary: 'Rubik', sans-serif;
    --font-secondary: 'Arial', sans-serif; // Add a secondary font if needed
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--font-primary);
    font-weight: 400;
    line-height: 1.55;
    overflow-x: hidden;
    background-color: ${props => props.theme.body};
    color: ${props => props.theme.text};
    transition: all 0.3s ease;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: 700;
    line-height: 1.3;
  }

  h1 {
    font-size: ${props => props.theme.fontxxxl};
    @media (max-width: 64em) {
      font-size: ${props => props.theme.fontxxl};
    }
  }

  h2 {
    font-size: ${props => props.theme.fontxxl};
    @media (max-width: 64em) {
      font-size: ${props => props.theme.fontxl};
    }
  }

  h3 {
    font-size: ${props => props.theme.fontxl};
    @media (max-width: 64em) {
      font-size: ${props => props.theme.fontlg};
    }
  }

  p {
    font-size: ${props => props.theme.fontmd};
    line-height: 1.8;
    margin-bottom: 1rem;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  ul, ol {
    list-style: none;
  }

  
`;

export default GlobalStyles;