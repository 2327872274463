import React from "react";
import styled from "styled-components";
import Carousel from "../Carousel";
import Button from "../Button";
import { FaRocket, FaLaughSquint, FaUsers } from 'react-icons/fa';

const About = () => {
  return (
    <Section id="about">
      <Container>
        <CarouselBox>
          <Carousel />
        </CarouselBox>
        <ContentBox>
          <Title>
            Embark on an Intergalactic Crypto Adventure
          </Title>
          <SubText>
            Introducing Skinny Bob MemeCoin: Where Alien Lore Meets Blockchain Innovation
          </SubText>
          <FeaturesContainer>
            <Feature>
              <FeatureIcon><FaRocket /></FeatureIcon>
              <FeatureContent>
                <FeatureTitle>Cosmic Potential</FeatureTitle>
                <FeatureText>Powered by cutting-edge blockchain technology, $SKIBOB is poised for interstellar growth.</FeatureText>
              </FeatureContent>
            </Feature>
            <Feature>
              <FeatureIcon><FaLaughSquint /></FeatureIcon>
              <FeatureContent>
                <FeatureTitle>Meme-Driven Innovation</FeatureTitle>
                <FeatureText>Blending humor with serious crypto potential, we're redefining the meme coin landscape.</FeatureText>
              </FeatureContent>
            </Feature>
            <Feature>
              <FeatureIcon><FaUsers /></FeatureIcon>
              <FeatureContent>
                <FeatureTitle>Thriving Community</FeatureTitle>
                <FeatureText>Join a passionate community of crypto enthusiasts, alien aficionados, and meme lovers.</FeatureText>
              </FeatureContent>
            </Feature>
          </FeaturesContainer>
          <ButtonContainer>
            <Button text="Join Our Community" link="https://twitter.com/SkinnyBobMeme" />
          </ButtonContainer>
        </ContentBox>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
`;

const Container = styled.div`
  width: 75%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
    flex-direction: column;
    gap: 4rem;
  }
`;

const CarouselBox = styled.div`
  width: 45%;
  @media (max-width: 64em) {
    width: 90%;
  }
`;

const ContentBox = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 64em) {
    width: 90%;
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  margin-bottom: 1rem;
  font-weight: 700;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  margin-bottom: 2rem;

  @media (max-width: 64em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

const FeatureIcon = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.accent};
`;

const FeatureContent = styled.div`
  flex: 1;
`;

const FeatureTitle = styled.h4`
  font-size: ${(props) => props.theme.fontmd};
  margin-bottom: 0.5rem;
`;

const FeatureText = styled.p`
  font-size: ${(props) => props.theme.fontsm};
  color: ${(props) => props.theme.textLight};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  @media (max-width: 64em) {
    justify-content: center;
  }
`;

export default About;