import React from 'react';
import styled from 'styled-components';
import boredAlienPirateImage from '../../assets/Nfts/BAP/bored-alien-pirate.png'; // Make sure to add your image

const BoredAlienPirate = () => {
  return (
    <Section id="bored-alien-pirates">
      <Container>
        <Title>Bored Alien Pirate Space Club</Title>
        <Content>
          <ImageContainer>
            <img src={boredAlienPirateImage} alt="Bored Alien Pirate" />
          </ImageContainer>
          <InfoContainer>
            <StatsGrid>
              <StatItem>
                <StatLabel>Floor Price</StatLabel>
                <StatValue>0.05 SOL</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Total Supply</StatLabel>
                <StatValue>1000</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Holders</StatLabel>
                <StatValue>5</StatValue>
              </StatItem>
              <StatItem>
                <StatLabel>Listed</StatLabel>
                <StatValue>350</StatValue>
              </StatItem>
            </StatsGrid>
            <Description>
              Embark on an intergalactic journey with our exclusive Bored Alien Pirate Space Club NFT collection on Solana. Each unique pirate alien is ready to explore the cosmos and plunder the universe!
            </Description>
            <ButtonContainer>
              <Button href="https://magiceden.io/marketplace/bapsc" target="_blank" rel="noopener noreferrer">
                View on MagicEden
              </Button>
              <Button href="https://www.tensor.trade/trade/bapc" target="_blank" rel="noopener noreferrer">
                View on Tensor
              </Button>
            </ButtonContainer>
          </InfoContainer>
        </Content>
      </Container>
    </Section>
  );
};

// Styled components
const Section = styled.section`
  background-color: ${props => props.theme.body};
  color: ${props => props.theme.text};
  padding: 5rem 0;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Title = styled.h2`
  font-size: ${props => props.theme.fontxxl};
  text-align: center;
  margin-bottom: 3rem;
`;

const Content = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  img {
    width: 100%;
    border-radius: 10px;
  }
`;

const InfoContainer = styled.div`
  flex: 1;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
`;

const StatItem = styled.div`
  background-color: ${props => props.theme.cardBg};
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
`;

const StatLabel = styled.p`
  font-size: ${props => props.theme.fontsm};
  margin-bottom: 0.5rem;
`;

const StatValue = styled.h3`
  font-size: ${props => props.theme.fontlg};
`;

const Description = styled.p`
  font-size: ${props => props.theme.fontmd};
  margin-bottom: 2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.a`
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.body};
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
`;

export default BoredAlienPirate;