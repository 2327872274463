import React, { useState, useEffect } from "react";
import { useWindowScroll } from "react-use";
import styled from "styled-components";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTop = () => {
  const { y } = useWindowScroll();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(y > 200);
  }, [y]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {isVisible && (
        <ScrollButton 
          onClick={scrollToTop} 
          aria-label="Scroll to top"
          role="button"
          tabIndex={0}
        >
          <FaArrowUp />
        </ScrollButton>
      )}
    </>
  );
};

const ScrollButton = styled.div`
  width: 3rem;
  height: 3rem;
  color: ${(props) => props.theme.text};
  background-color: ${(props) => props.theme.body};
  font-size: ${(props) => props.theme.fontxl};
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.2s ease;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: scale(1.1);
    background-color: ${(props) => props.theme.accent};
    color: ${(props) => props.theme.body};
  }

  &:active {
    transform: scale(0.9);
  }
`;

export default ScrollToTop;