import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import Button from "./Button";
import Toggle from "./Toggler";
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = ({ theme, themeToggler }) => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView({ behavior: "smooth", block: "start" });
    setIsOpen(false);
  };

  const navItems = [
    { id: "home", label: "Home" },
    { id: "about", label: "About" },
    { id: "howtobuy", label: "How Buy" },
    { id: "tokenomics", label: "Tokenomics" },
    { id: "roadmap", label: "Roadmap" },
    { id: "showcase", label: "Showcase" },
    { id: "team", label: "Team" },
    { id: "faqs", label: "FAQs" },
  ];

  return (
    <NavbarContainer>
      <NavContent>
        <Logo />
        <NavItems>
          {navItems.map((item) => (
            <NavItem key={item.id} onClick={() => scrollTo(item.id)}>
              {item.label}
            </NavItem>
          ))}
        </NavItems>
        <RightSection>
          <Toggle theme={theme} toggleTheme={themeToggler} />
          <BuyButton>
            <Button
              text="Buy Now"
              link="https://app.uniswap.org/#/swap?outputCurrency=0x1a936953C6C5f832032C2A3CE40dA1C0934860a6&chain=mainnet"
            />
          </BuyButton>
          <MenuToggle onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </MenuToggle>
        </RightSection>
      </NavContent>
      <MobileMenu isOpen={isOpen}>
        {navItems.map((item) => (
          <MobileNavItem key={item.id} onClick={() => scrollTo(item.id)}>
            {item.label}
          </MobileNavItem>
        ))}
        <MobileBuyButton>
          <Button
            text="Buy Now"
            link="https://app.uniswap.org/#/swap?outputCurrency=0x1a936953C6C5f832032C2A3CE40dA1C0934860a6&chain=mainnet"
          />
        </MobileBuyButton>
      </MobileMenu>
    </NavbarContainer>
  );
};

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${props => props.theme.navHeight};
  background-color: ${props => props.theme.body};
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const NavItems = styled.ul`
  display: flex;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin: 0 1rem;
  cursor: pointer;
  position: relative;
  color: ${props => props.theme.text};
  transition: color 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: ${props => props.theme.accent};
    transition: width 0.3s ease;
  }

  &:hover {
    color: ${props => props.theme.accent};
    &::after {
      width: 100%;
    }
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const BuyButton = styled.div`
  margin-left: 1rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding-right:30px;
  
  color: ${props => props.theme.text};
  @media (max-width: 1024px) {
    display: block;
  }
`;

const MobileMenu = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: ${props => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.theme.body};
    padding: 2rem;
    transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease;
    overflow-y: auto;
  }
`;

const MobileNavItem = styled(NavItem)`
  margin: 1rem 0;
  font-size: 1.2rem;
`;

const MobileBuyButton = styled.div`
  margin-top: 2rem;
`;

export default Navbar;