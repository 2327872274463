import React from "react";
import styled from "styled-components";
import { FaRocket, FaChartLine, FaHandshake, FaLock } from 'react-icons/fa';

const roadmapData = [
  {
    phase: "Phase 1: Liftoff",
    icon: <FaRocket />,
    items: [
      "Multi-chain token launch (ETH, BSC, Polygon, Solana)",
      "CoinGecko & CoinMarketCap listings",
      "Achieve 10,000 holders milestone",
      "Launch Skinny Bob Meme Contest"
    ]
  },
  {
    phase: "Phase 2: Orbit",
    icon: <FaChartLine />,
    items: [
      "Implement staking and yield farming",
      "Launch Skinny Bob NFT collection",
      "Launch Bored Alien Pirate NFT collection",
      "Develop cross-chain bridge",
      "Reach 50,000 holders"
    ]
  },
  {
    phase: "Phase 3: Exploration",
    icon: <FaHandshake />,
    items: [
      "Strategic partnerships with major DeFi projects",
      "Launch Skinny Bob DEX",
      "Implement governance system",
      "Achieve 100,000 holders"
    ]
  },
  {
    phase: "Phase 4: Colonization",
    icon: <FaLock />,
    items: [
      "Major CEX listings",
      "Launch Skinny Bob Launchpad",
      "Develop Skinny Bob metaverse",
      "Reach 500,000 holders and beyond"
    ]
  }
];

const Roadmap = () => {
  return (
    <Section id="roadmap">
      <Container>
        <Title>Intergalactic Expansion Roadmap</Title>
        <RoadmapContainer>
          {roadmapData.map((phase, index) => (
            <RoadmapCard key={index}>
              <PhaseIcon>{phase.icon}</PhaseIcon>
              <PhaseTitle>{phase.phase}</PhaseTitle>
              <PhaseList>
                {phase.items.map((item, itemIndex) => (
                  <PhaseItem key={itemIndex}>{item}</PhaseItem>
                ))}
              </PhaseList>
            </RoadmapCard>
          ))}
        </RoadmapContainer>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    width: 100%;
    padding: 2rem;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.text};
  width: fit-content;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const RoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
`;

const RoadmapCard = styled.div`
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px ${(props) => props.theme.shadowColor};
`;

const PhaseIcon = styled.div`
  font-size: 2.5rem;
  color: ${(props) => props.theme.accent};
  margin-bottom: 1rem;
`;

const PhaseTitle = styled.h3`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.text};
  margin-bottom: 1.5rem;
`;

const PhaseList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const PhaseItem = styled.li`
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
  font-size: ${(props) => props.theme.fontmd};
  position: relative;
  padding-left: 20px;

  &:before {
    content: "▹";
    color: ${(props) => props.theme.accent};
    position: absolute;
    left: 0;
  }
`;

export default Roadmap;