import React, { useState } from "react";
import UniswapWidget from "../UniswapWidget";
import { useTheme } from "styled-components";
import styled from "styled-components";
import { FaEthereum, FaWallet, FaExchangeAlt, FaLink, FaCopy, FaInfoCircle } from "react-icons/fa";
import { SiBinance, SiPolygon } from "react-icons/si";
import { SiSolana } from "react-icons/si";

const Howtobuy = () => {
  const [copiedAddress, setCopiedAddress] = useState(null);
  const theme = useTheme();

  const blockchains = [
    {
      name: "Ethereum",
      icon: <FaEthereum />,
      color: "#627EEA",
      contractAddress: "0x1a936953C6C5f832032C2A3CE40dA1C0934860a6",
      swapLink: "https://app.uniswap.org/#/swap?outputCurrency=0x1a936953C6C5f832032C2A3CE40dA1C0934860a6&chain=mainnet",
      explorer: "https://etherscan.io/token/0x1a936953C6C5f832032C2A3CE40dA1C0934860a6"
    },
    {
      name: "Binance Smart Chain",
      icon: <SiBinance />,
      color: "#F3BA2F",
      contractAddress: "0xD7124a392C5A127C5ccB40c601F6e80AFE5ebd8c",
      swapLink: "https://pancakeswap.finance/swap?outputCurrency=0xD7124a392C5A127C5ccB40c601F6e80AFE5ebd8c",
      explorer: "https://bscscan.com/token/0xD7124a392C5A127C5ccB40c601F6e80AFE5ebd8c"
    },
    {
      name: "Polygon",
      icon: <SiPolygon />,
      color: "#8247E5",
      contractAddress: "0xEdC59982d37435eC0C8C755B7eDC25E39A3A223a",
      swapLink: "https://quickswap.exchange/#/swap?outputCurrency=0xEdC59982d37435eC0C8C755B7eDC25E39A3A223a",
      explorer: "https://polygonscan.com/token/0xEdC59982d37435eC0C8C755B7eDC25E39A3A223a"
    },
    {
      name: "Solana",
      icon: <SiSolana />,
      color: "#14F195",
      contractAddress: "HmPpW9zeB4RLjhbtPyoY2FfHkeqjMEbccvL1SBnQ7i3r",
      swapLink: "#",
      explorer: "https://solscan.io/token/HmPpW9zeB4RLjhbtPyoY2FfHkeqjMEbccvL1SBnQ7i3r",
      swapDisabled: true
    }
  ];

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedAddress(text);
      setTimeout(() => setCopiedAddress(null), 2000);
    });
  };

  return (
    <Section id="howtobuy">
      <Container>
        <Title>How to Buy $SKIBOB</Title>
        <FlexContainer>
          <SwapBox>
            <SwapTitle>Swap for $SKIBOB</SwapTitle>
            <WidgetWrapper>
              <UniswapWidget theme={theme} />
            </WidgetWrapper>
          </SwapBox>
          <InstructionBox>
            <StepContainer>
              <Step>
                <StepIcon><FaWallet /></StepIcon>
                <StepContent>
                  <StepTitle>1. Create a Wallet</StepTitle>
                  <StepDescription>
                    Download MetaMask or your preferred wallet. Set it up and ensure it's connected to your chosen network (Ethereum, BSC, or Polygon).
                  </StepDescription>
                </StepContent>
              </Step>
              <Step>
                <StepIcon><FaEthereum /></StepIcon>
                <StepContent>
                  <StepTitle>2. Get Native Tokens</StepTitle>
                  <StepDescription>
                    Purchase ETH, BNB, or MATIC depending on your chosen network. You can buy directly through your wallet or transfer from an exchange.
                  </StepDescription>
                </StepContent>
              </Step>
              <Step>
                <StepIcon><FaExchangeAlt /></StepIcon>
                <StepContent>
                  <StepTitle>3. Swap for $SKIBOB</StepTitle>
                  <StepDescription>
                    Use our widget or connect to the appropriate DEX for your network. Paste the $SKIBOB contract address, set the amount, and confirm the swap.
                  </StepDescription>
                </StepContent>
              </Step>
            </StepContainer>
          </InstructionBox>
        </FlexContainer>
        <BlockchainInfo>
          <BlockchainTitle>$SKIBOB on Multiple Chains</BlockchainTitle>
          <ChainGrid>
            {blockchains.map((chain, index) => (
              <ChainCard key={index} color={chain.color}>
                <ChainIcon>{chain.icon}</ChainIcon>
                <ChainName>{chain.name}</ChainName>
                <ChainAddress>
                  <strong>Contract:</strong> 
                  <AddressText>{chain.contractAddress.slice(0, 6)}...{chain.contractAddress.slice(-4)}</AddressText>
                  <CopyButton onClick={() => copyToClipboard(chain.contractAddress)}>
                    <FaCopy />
                    {copiedAddress === chain.contractAddress && <CopiedTooltip>Copied!</CopiedTooltip>}
                  </CopyButton>
                </ChainAddress>
                <ChainLinks>
                  {chain.swapDisabled ? (
                    <DisabledLink>
                      <FaInfoCircle /> Swapping Not Available
                    </DisabledLink>
                  ) : (
                    <ChainLink href={chain.swapLink} target="_blank" rel="noopener noreferrer">
                      <FaExchangeAlt /> Swap
                    </ChainLink>
                  )}
                  <ChainLink href={chain.explorer} target="_blank" rel="noopener noreferrer">
                    <FaLink /> Explorer
                  </ChainLink>
                </ChainLinks>
              </ChainCard>
            ))}
          </ChainGrid>
        </BlockchainInfo>
      </Container>
    </Section>
  );
};

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  padding: 4rem 0;
  transition: all 0.3s ease;
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 2rem;
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const SwapBox = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px ${(props) => props.theme.shadowColor};
`;

const SwapTitle = styled.h3`
  font-size: 2rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 1rem;
  text-align: center;
`;

const InstructionBox = styled.div`
  flex: 1;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px ${(props) => props.theme.shadowColor};
`;

const StepIcon = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.accent};
`;

const StepContent = styled.div`
  flex: 1;
`;

const StepTitle = styled.h4`
  font-size: 1.5rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 0.5rem;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: ${(props) => props.theme.textLight};
  line-height: 1.5;
`;

const BlockchainInfo = styled.div`
  margin-top: 4rem;
`;

const BlockchainTitle = styled.h3`
  font-size: 2.5rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 2rem;
  text-align: center;
`;

const ChainGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ChainCard = styled.div`
  background-color: ${(props) => props.theme.cardBg};
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 10px 30px ${(props) => props.theme.shadowColor};
  border-top: 5px solid ${(props) => props.color};
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ChainIcon = styled.div`
  font-size: 3rem;
  color: ${(props) => props.color};
  margin-bottom: 1rem;
`;

const ChainName = styled.h4`
  font-size: 1.5rem;
  color: ${(props) => props.theme.text};
  margin-bottom: 1rem;
`;

const ChainAddress = styled.p`
  font-size: 0.9rem;
  color: ${(props) => props.theme.textLight};
  margin-bottom: 1rem;
  word-break: break-all;
`;

const ChainLinks = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

const ChainLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.body};
  padding: 0.5rem 1rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px ${(props) => props.theme.shadowColor};
  }
`;

const DisabledLink = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.disabledBg || '#cccccc'};
  color: ${(props) => props.theme.disabledText || '#666666'};
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  cursor: not-allowed;
`;

const WidgetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const AddressText = styled.span`
  margin-left: 0.5rem;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 0.5rem;
  position: relative;
  color: ${(props) => props.theme.accent};
`;

const CopiedTooltip = styled.span`
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.body};
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8rem;
`;

export default Howtobuy;